import React, { FunctionComponent } from 'react';
import { graphql } from 'gatsby';

import { Layout } from '../components/Layout';
import { Posts as PostItems } from '../components/Posts';
import { PostNode } from '../components/Post';
import { Pagination } from '../components/Pagination';
import { PageContext } from '../models/page-context';

type Props = {
    pageContext: PageContext,
    data: {
        site: {
            siteMetadata: {
                url: string
            }
        }
        allMarkdownRemark: {
            edges: {
                node: PostNode
            }[],
        }
    }
}

const Posts: FunctionComponent<Props> = ({ data, pageContext }) => {
    const { first, last, currentPage } = pageContext;
    const { url } = data.site.siteMetadata;

    return (
        <Layout
            url={`${url}${ currentPage === 1 ? '' : `posts/page/${currentPage}` }`}
            image={`${url}assets/icon.png`}>
            <PostItems posts={data.allMarkdownRemark.edges.map(({ node }) => node)} />
            <Pagination
                prev={ first ? null : currentPage === 2 ? '/' : `/posts/page/${currentPage - 1}`}
                next={ last ? null : `/posts/page/${currentPage + 1}` }
            />
        </Layout>
    );
}

export default Posts;

export const query = graphql`
    query postsListQuery($skip: Int!, $limit: Int!) {
        site {
            siteMetadata {
                url
            }
        }
        allMarkdownRemark(
            sort: { fields: [frontmatter___date], order: DESC }
            limit: $limit
            skip: $skip
        ) {
            edges {
                node {
                    id
                    frontmatter {
                        title
                        category
                        tags
                        date(formatString: "YYYY-MM-DD")
                    }
                    fields {
                        slug
                    }
                    excerpt(truncate: true)
                }
            }
        }
    }
`;
